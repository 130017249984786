import React, {  useState,useEffect } from "react";
import NavbarLandingPage from "./NavbarLandingPage";
import HeaderSection from "./HeaderSection";
import Stories from "./Stories";
import Frame from "./Frame";
import TopVendors from "./TopVendors";
import InvitationCardsBlock from "./InvitationCardsBlock";
import Footer from "../Footer";
import axios from "axios";
import loadingspiner from "./images/icons8-rhombus-loader.gif";
const LandingPage = () => {
  const [blog, setBlog] = useState([]);
  useEffect(() => {
    if (blog) {
      axios({
        method: "get",
        url: "https://us-central1-zifaf-app.cloudfunctions.net/webapp/api/blog",
        responseType: "json",
      }).then(function (response) {
        setBlog(response.data);
      });
    }
  }, []);
  if (blog == null || Object.keys(blog).length === 0) {
    return (
      <div className="spinner-loading">
        <img src={loadingspiner} />
      </div>
    );
  }

  return (
    <div>
      <div className="landing-page">
        <NavbarLandingPage />
        <HeaderSection />
        {/* <Frame /> */}
        <TopVendors />
        <InvitationCardsBlock />
        <Stories story="مقالات" blog={blog} />
      </div>
      <Footer />
    </div>
  );
};

export default LandingPage;
