export function collectData(categoryType, data) {
  let collectedData = [];
  if (
    categoryType === "Studios" ||
    categoryType == "Zaffat" ||
    categoryType == "DJs" ||
    categoryType == "Venues"
  ) {
    data.forEach((object) => {
      collectedData.push({
        id: object.businessUid,
        name: object.profileHeader.name,
        description: object.profileHeader.description,
        address: object.profileHeader.address,
        logo: object.profileHeader.logo,
        details: object.details,
        pricedDetails:object.pricedDetails,
        guests: object.guests,
        location: object.location,
        mix: object.mix,
        price: object.price,
        area: object.area,
        images: object.images,
        gallery: object.gallery,
        video: object.video,
      });
    });
  } else if (categoryType == "Tawzi3at" || categoryType == "Invitations") {
    data.forEach((object) => {
      collectedData.push({
        id: object.businessUid,
        name: object.name,
        description: object.description,
        address: object.address,
        logo: object.images[0],
        details: object.details,
        guests: object.guests,
        pricedDetails:object.pricedDetails,
        location: object.location,
        mix: object.mix,
        price: object.price,
        area: object.area,
        images: object.images,
        gallery: object.gallery,
        video: object.video,
      });
    });
  } else {
    return data;
  }
  return collectedData;
}
