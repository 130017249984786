import React from "react";

class ValueCard extends React.Component {
   
    render() {
        return (
            <div className="value-frame shadow">
                <div className="value-image">
                    <img src={this.props.valueImage} alt="" />
                </div>
                <div className="value-text">
                    {this.props.valueText}
                </div>
            </div>
        );
    }
}

export default ValueCard;