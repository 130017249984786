import React from "react";
import appStore from "../../src/components/landingPage/images/download_appstore.png"
import googlePlay from "../../src/components/landingPage/images/google-play-badge.png"

class DownloadBadges extends React.Component {
    render() {
        return <div className="download-badges">
            <div className="badge">
                <a href='https://play.google.com/store/apps/details?id=com.zifaf.app&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1'>
                    <img alt='احصل عليه من Google Play' src={googlePlay}/>
                </a>
            </div>
            <div className="badge" className="badge">
                <a href='https://apps.apple.com/il/app/zifaf/id1579058899'>
                    <img alt='احصل عليه من App Store' src={appStore}/>
                </a>
            </div>
        </div>
    }

}

export default DownloadBadges;