import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import { faLocationDot, faEnvelope, faPhone } from "@fortawesome/free-solid-svg-icons";

library.add(faLocationDot, faPhone, faEnvelope);

class ContactInfo extends React.Component {
    render() {
        return (
            <div className="contact-item">
                <div className="contact-title">
                    <FontAwesomeIcon icon={this.props.icon} color="var(--background-color2)" />
                    <span className="mr-3">{this.props.contactTitle}</span>
                </div>
                <div className="contact-content">
                    {this.props.contactContent}
                    <hr color="#ffe38d" />
                </div>
            </div>

        );
    }
}

export default ContactInfo;