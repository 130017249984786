import React from "react";
import JoinCta from './JoinCta'

class CrmSection extends React.Component {
    render() {
        return (
            <div className="container">
                <div className="business-wrapper">
                    <div className="business-text" >
                        {this.props.sectionText}
                        <br />
                        <JoinCta />

                    </div>
                    <div className="crm-demo">
                        <img src="images/mac_mockup.png" alt="" />
                    </div>
                </div>
            </div>
        );
    }
}

export default CrmSection;