import React from "react";
import NavbarLandingPage from "../landingPage/NavbarLandingPage";
import Price from "./Price";
import Footer from "../Footer";

const PricingDown = (props) => {
    return (
        <div className="price-page">
            <div className="landing-page">
                <NavbarLandingPage />
            </div>
            <Price/>
            <Footer/>
        </div>    );
};
export default PricingDown;
