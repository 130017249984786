import React from "react";
import HeaderSection from "./HeaderSection";
import SectionTitle from "./SectionTitle";
import ValueCompositions from "./ValueCompositions";
import CategoryCard from "./CategoryCard";
import CrmSection from "./CrmSection";
import Footer from "./Footer";
import OwlCarousel from "react-owl-carousel-rtl";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
// import { BrowserRouter, Route, Routes, Switch } from 'react-router-dom';

class App extends React.Component {
  render() {
    const breakPoints = [
      { width: 1, itemsToShow: 1 },
      { width: 550, itemsToShow: 2, itemsToScroll: 2 },
      { width: 768, itemsToShow: 3 },
      { width: 1200, itemsToShow: 4 },
    ];

    return (
      <div>
        <HeaderSection />
        <div id="why" className="value-section">
          <SectionTitle
            title="ليش تطبيق زفاف ؟"
            extension="images/basataExt.png"
          />
          <div className="container position-relative mt-5">
            <ValueCompositions />
          </div>
        </div>
        <SectionTitle
          id="categories"
          title="تصنيفات زفاف"
          extension="images/tasneefatExt.png"
        />

        <div className="container mt-5">
          <OwlCarousel
            className="owl-theme"
            nav={false}
            loop={false}
            margin={10}
            items={breakPoints}
          >
            <CategoryCard
              imageUrl="https://i.pinimg.com/564x/99/e3/14/99e31457f7a4e8fe71a369671e9f3f5d.jpg"
              categoryName="قاعات أفراح"
            />
            <CategoryCard
              imageUrl="https://images.unsplash.com/photo-1516035069371-29a1b244cc32?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=764&q=80"
              categoryName="استديوهات"
            />
            <CategoryCard
              imageUrl="https://i.pinimg.com/564x/27/8d/b4/278db4cf81727cdfea25a5f4b7383ee9.jpg"
              categoryName="كروت أعراس"
            />
            <CategoryCard
              imageUrl="https://firebasestorage.googleapis.com/v0/b/zifaf-app.appspot.com/o/categories%2Fzaffat.gif?alt=media&token=d776c484-7c3c-4c09-8c06-708780423f84"
              categoryName="فرق زفات"
            />
            <CategoryCard
              imageUrl="https://i.pinimg.com/564x/d5/c4/58/d5c458eba8606b5fd455083b4c415749.jpg"
              categoryName="إكسسوارات"
            />
            <CategoryCard
              imageUrl="https://images.pexels.com/photos/6119140/pexels-photo-6119140.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260"
              categoryName="توزيعات"
            />
            <CategoryCard
              imageUrl="https://i.pinimg.com/564x/6f/b9/7a/6fb97a8508b8afa14b7723678602b7b9.jpg"
              categoryName="زينة"
            />
            <CategoryCard
              imageUrl="https://images.unsplash.com/photo-1541126274323-dbac58d14741?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=687&q=80"
              categoryName="دي جيهات"
            />
            <CategoryCard
              imageUrl="https://images.unsplash.com/photo-1632396690014-cf7a0a2f3bbb?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=687&q=80"
              categoryName="كيك"
            />
          </OwlCarousel>
        </div>
        <SectionTitle
          id="business"
          title="صاحب مشروع؟"
          extension="images/tanzeem-transparent.png"
        />
        <CrmSection
          sectionText=" الموضوع مش بس تطبيق،
                        الحجوزات مرتبة، مع مواعيدها
                        وتفاصيلها"
        />
        <Footer />
      </div>
    );
  }
}

export default App;
