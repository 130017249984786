import React, { useEffect, useState } from "react";
import axios from "axios";
import loadingspiner from "../../components/landingPage/images/icons8-rhombus-loader.gif";
import noData from "../../components/landingPage/images/icons8-sad-cloud-100.png";
import { Link } from "react-router-dom";

const BlogSection = () => {
  const [blog, setBlog] = useState([]);
  useEffect(() => {
    axios({
      method: "get",
      url: "https://us-central1-zifaf-app.cloudfunctions.net/webapp/api/blog",
      responseType: "json",
    }).then(function (response) {
      setBlog(response.data);
    });
  }, []);
  if (blog.length == 0) {
    // loading
    return (
      <div className="spinner-loading">
        <img src={loadingspiner} />
      </div>
    );
  }
  if (blog == null) {
    return (
      <div className="no-data">
        <img src={noData} />
        <h3>لا يوجد بيانات في هذه الصفحة</h3>
        <h4>اختر حقل آخر </h4>
      </div>
    );
  }
  const firstBlog = blog[0];
  return (
      <div className="blog">
        <div
          className="first-blog"
          style={{ backgroundImage: `url("${firstBlog.articleImage}")` }}
        >
          <div className="">
            <div className="blog-header-title">
              <p>{firstBlog.articleTitle}</p>
            </div>
            <div className="blog-header-paragraph">
              <p>{firstBlog.articleSubtitle}</p>
              <div className="block-header-svg">
                <Link
                  to={`/article/${firstBlog.id}`}
                  state={{ blog: firstBlog }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="64"
                    height="38"
                    viewBox="0 0 64 38"
                    fill="none"
                  >
                    <line x1="64" y1="19" x2="-8.74228e-08" y2="19" stroke="white" strokeWidth="2"
                    />
                    <line
                      x1="23.6163"
                      y1="0.787505"
                      x2="0.616309"
                      y2="18.7875"
                      stroke="white"
                      strokeWidth="2"
                    />
                    <line
                      y1="-1"
                      x2="29.2062"
                      y2="-1"
                      transform="matrix(-0.787505 -0.616308 -0.616308 0.787505 23 38)"
                      stroke="white"
                      strokeWidth="2"
                    />
                  </svg>
                </Link>
              </div>
            </div>
          </div>
        </div>
        <div className="blog-cards">
          {blog.slice(1).map((object, index) => {
            return (
              <div key={index} className="blog-card">
                <div className="img-blog-wrapper">
                  <img src={object.articleImage} />
                </div>
                <div className="blog-details">
                  <div className="blog-title">{object.articleTitle}</div>
                  <div className="blog-paragraph">
                    <div className="block-svg">
                      <Link
                        to={`/article/${object.id}`}
                        state={{ blog: object }}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="53"
                          height="33"
                          viewBox="0 0 53 33"
                          fill="none"
                        >
                          <line
                            x1="52.8979"
                            y1="16.4944"
                            x2="0.507496"
                            y2="16.4944"
                            stroke="#45A89E"
                            strokeWidth="1.6372"
                          />
                          <line
                            x1="19.8395"
                            y1="1.58508"
                            x2="1.01165"
                            y2="16.3199"
                            stroke="#45A89E"
                            strokeWidth="1.6372"
                          />
                          <line
                            y1="-0.818601"
                            x2="23.9082"
                            y2="-0.818601"
                            transform="matrix(-0.787505 -0.616308 -0.616308 0.787505 19.335 32.0469)"
                            stroke="#45A89E"
                            strokeWidth="1.6372"
                          />
                        </svg>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
  );
};
export default BlogSection;
