import React, { useEffect, useState } from "react";
import axios from "axios";
import { collectData } from "../../utilities";
import {Link, useNavigate} from "react-router-dom";

const TopVendors = () => {
  const [venues, setVenues] = useState(null);
  const categoryType = "Venues";
  useEffect(() => {
    axios({
      method: "get",
      url: `https://us-central1-zifaf-app.cloudfunctions.net/webapp/api/Venues`,
      responseType: "json",
    }).then(function (response) {
      //check categoryType and return data
      const collectionsData = collectData(categoryType, response.data);
      setVenues(collectionsData);
    });
  }, []);
  const navigate=useNavigate()

  if (venues == null) {
    return;
  }
  return (
    <div className="topVendors">
      <div className="topTitle">
        <p>قاعات موصى بها في فلسطين</p>
      </div>
      <div className="topVendors-blocks">
        {venues.slice(0, 5).map((object, index) => {
          return (
            <div key={index} className="topVendors-block">
              <div
                className="block-photo"
                style={{ backgroundImage: `url("${object.images[0]}")` }}
              >
                <div className="icon">
                  <svg viewBox="0 0 40 34" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fillRule="evenodd" clipRule="evenodd" d="M7.88601 0.312816C1.87574 1.84835 -1.46306 8.30247 0.62258 14.3533C1.97033 18.2635 4.62316 21.0109 15.2458 29.4988L19.9926 33.2917L24.7394 29.4988C37.0399 19.6703 39.6037 16.6311 39.9697 11.4436C40.7447 0.461405 26.4445 -4.32984 20.5828 4.94816C19.9684 5.92052 20.0168 5.92052 19.4024 4.94816C16.9811 1.11568 12.2294 -0.796984 7.88601 0.312816Z" fill="white"/>
                  </svg>
                </div>
              </div>
              <div className="block-details">
                <p className="block-details-name">{object.name}</p>
                <div className="details">
                  <p>{object.price}₪</p>
                  <div className="venue-img">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="28"
                      height="28"
                      viewBox="0 0 28 28"
                      fill="none"
                    >
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M10.4675 4.81382L7.98361 7.90204H13.2056H18.4277L15.9438 4.81382C14.5775 3.11529 13.3454 1.72559 13.2056 1.72559C13.0659 1.72559 11.8338 3.11529 10.4675 4.81382ZM1.41414 8.06458L0.207777 10.5027H2.80319H5.3986L4.19224 8.06458C3.52844 6.72364 2.90364 5.62651 2.80319 5.62651C2.70274 5.62651 2.07794 6.72364 1.41414 8.06458ZM22.2191 8.06458L21.0127 10.5027H23.6081H26.2035L24.9972 8.06458C24.3334 6.72364 23.7086 5.62651 23.6081 5.62651C23.5077 5.62651 22.8829 6.72364 22.2191 8.06458ZM8.00442 12.4531V15.7039H6.70411H5.4038V13.7534V11.803H2.80319H0.202576V19.6048V27.4067H3.41173H6.62089L6.83317 26.0794C7.2847 23.2564 9.56511 20.1704 12.2109 18.8022C13.1182 18.3331 13.2931 18.3331 14.2004 18.8022C16.8462 20.1704 19.1266 23.2564 19.5781 26.0794L19.7904 27.4067H22.9996H26.2087V19.6048V11.803H23.6081H21.0075V13.7534V15.7039H19.7072H18.4069V12.4531V9.20235H13.2056H8.00442V12.4531ZM15.0082 11.3007C15.6636 11.9558 15.8063 12.4206 15.8063 13.9013V15.7039H13.2056H10.605V13.9013C10.605 11.7321 11.5458 10.5027 13.2056 10.5027C13.8389 10.5027 14.5053 10.7975 15.0082 11.3007Z"
                        fill="#0C242D"
                        fillOpacity="0.44"
                      />
                    </svg>
                  </div>
                </div>
              </div>
              <Link to={`/details/${categoryType}/${object.id}`}>
              <div className="more-button">
                <span>
                  اعرف أكثر
                </span>
              </div>
              </Link>
            </div>
          );
        })}
        <div className="all-vendors"
             onClick={((e)=>{navigate(`/services/${categoryType}`)})}>
          جميع القاعات        </div>
      </div>
    </div>
  );
};

export default TopVendors;
