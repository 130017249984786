import React from "react";
import styled from "styled-components";

const CategoryGallery = (props) => {
  const { gallery } = props;

  let VenuesWrapper = styled.div`

align-items: center;
gap: 40px;
width: 675px
object-fit: contain;
border-radius: 22.585px;
background-image: url('${gallery}');
background-size: contain;
background-repeat: no-repeat;
width: 70vw;
height: 675px;
background-position: center;
margin: 0 auto;
// -webkit-box-shadow: 0px 0px 15px 6px rgba(0,0,0,0.15); 
// box-shadow: 0px 0px 15px 6px rgba(0,0,0,0.15);
`;
  // return <VenuesWrapper className="VenueWrapper item" />;
  return <img src={gallery} className="VenueWrapper item" />;
};

export default CategoryGallery;
