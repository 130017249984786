import React from "react";
import Logo from './Logo';
import DownloadBadges from "./DownloadBadges";
import SocialMediaIcons from "./SocialMediaIcons";

class DownloadFooter extends React.Component {
    render() {
        return (
            <div className="download-footer-wrapper">
                <div className="download-label-container mb-3">
                    <Logo color="black" />
                    <div className="download-label">
                        قم بتنظيم عرسك بكافة تفاصيله وبالطريقة والمكان اللي بناسبكم الان من خلال تطبيق زفاف
                    </div>
                </div>
                <DownloadBadges />
                <div className="social-media-section mt-3">
                    <div className="social-media-label">
                        تابعنا على السوشيال ميديا
                    </div>
                    <SocialMediaIcons />
                </div>
            </div>
        );
    }
}

export default DownloadFooter