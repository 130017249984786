import React, { useEffect, useState } from "react";
import noData from "../../images/icons8-sad-cloud-100.png";
import { Link, useNavigate } from "react-router-dom";
import loadingspiner from "../../images/icons8-rhombus-loader.gif";
import { collectData } from "../../../../utilities";
import axios from "axios";
import Footer from "../../../Footer";

const CategoryPage = (props) => {
  const [isActive, setIsActive] = useState(null);
  const [category, setCategory] = useState([]);
  const [isClicked, setIsClicked] = useState(false);
  const categoryType = props.active;
  const handleClick = () => {
    setIsClicked((current) => !current);
  };
  useEffect(() => {
    setCategory(null);
    axios({
      method: "get",
      url: `https://us-central1-zifaf-app.cloudfunctions.net/webapp/api/${categoryType}`,
      responseType: "json",
    }).then(function (response) {
      //check categoryType and return data
      const collectionsData = collectData(categoryType, response.data);
      setCategory(collectionsData);
    });
  }, [props.active]);

  function myFunction(e, id) {
    setIsActive(id);
  }

  if (category == null) {
    // loading
    return (
      <div className="spinner-loading">
        <img src={loadingspiner} />
      </div>
    );
  }
  if (Object.keys(category).length == 0) {
    return (
      <div className="no-data">
        <h3>لا يوجد بيانات في هذه الصفحة</h3>
        <h4>اختر حقل آخر </h4>
        <img src={noData} />
      </div>
    );
  }

  return (
    <div className="venues-blocks">
      {category.map((object, index) => {
        return (
          <div className="venue-card" key={index}>
            <div className="venue-photo">
              <div
                className="photo-category-wrapper"
                style={{ backgroundImage: `url("${object.images[0]}")` }}
              >
              </div>
              <div className="venue-photo-svg">
                <div className="business-address"  style={{width: "unset"}}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 28 40"
                    fill="white"
                    width="10px"
                    height="10px"
                  >
                    <path
                      d="M14.1496 1.04297C6.5372 1.04297 0.380371 7.19979 0.380371 14.8122C0.380371 23.0148 9.07467 34.3252 12.6547 38.633C13.4415 39.5772 14.8774 39.5772 15.6642 38.633C19.2246 34.3252 27.9189 23.0148 27.9189 14.8122C27.9189 7.19979 21.7621 1.04297 14.1496 1.04297ZM14.1496 19.7298C11.4351 19.7298 9.23204 17.5267 9.23204 14.8122C9.23204 12.0977 11.4351 9.89463 14.1496 9.89463C16.8641 9.89463 19.0672 12.0977 19.0672 14.8122C19.0672 17.5267 16.8641 19.7298 14.1496 19.7298Z"
                      fill="white"
                    />
                  </svg>
                </div>
                {object.location}
                {/* <svg viewBox="0 0 40 34" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path fillRule="evenodd" clipRule="evenodd" d="M7.88601 0.312816C1.87574 1.84835 -1.46306 8.30247 0.62258 14.3533C1.97033 18.2635 4.62316 21.0109 15.2458 29.4988L19.9926 33.2917L24.7394 29.4988C37.0399 19.6703 39.6037 16.6311 39.9697 11.4436C40.7447 0.461405 26.4445 -4.32984 20.5828 4.94816C19.9684 5.92052 20.0168 5.92052 19.4024 4.94816C16.9811 1.11568 12.2294 -0.796984 7.88601 0.312816Z" fill="white" />
                </svg> */}
              </div>
            </div>
            <div className="venues-form">
              <div className="venue-title-and-content">
                <div className="venue-title">{object.name}</div>
                {object.description ? (
                  <div className="venue-content">
                    {object.description.length > 44 ? (
                      <div>
                        {object.description.substring(0, 44)}
                        <p
                          className={
                            isActive === object.id
                              ? "more-description"
                              : "display-description-none"
                          }
                        >
                          {isActive ? object.description.substring(44) : null}
                        </p>
                        <span
                          className={
                            isActive === object.id
                              ? "display-description-none"
                              : "more-description"
                          }
                          id={object.id}
                          onClick={(e) => myFunction(e, object.id)}
                        >
                          ...المزيد
                        </span>
                      </div>
                    ) : (
                      <p>{object.description}</p>
                    )}
                  </div>
                ) : null}
              </div>
              <div className="venue-number-and-price">
                {categoryType == "Venues" ? (
                  <div className="venue-numbers">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="18"
                      viewBox="0 0 24 18"
                      fill="none"
                    >
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M17.1226 10.3633C18.5387 11.38 19.531 12.7576 19.531 14.5943V17.8742H23.6656V14.5943C23.6656 12.2109 19.9755 10.8006 17.1226 10.3633Z"
                        fill="#45A89E"
                      />
                      <path
                        d="M9.19371 9.1311C11.4772 9.1311 13.3283 7.17317 13.3283 4.75793C13.3283 2.3427 11.4772 0.384766 9.19371 0.384766C6.91022 0.384766 5.05908 2.3427 5.05908 4.75793C5.05908 7.17317 6.91022 9.1311 9.19371 9.1311Z"
                        fill="#45A89E"
                      />
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M15.396 9.1311C17.6804 9.1311 19.5306 7.17411 19.5306 4.75793C19.5306 2.34176 17.6804 0.384766 15.396 0.384766C14.9102 0.384766 14.4554 0.494095 14.0212 0.647155C14.8792 1.77325 15.396 3.20546 15.396 4.75793C15.396 6.31041 14.8792 7.74262 14.0212 8.86871C14.4554 9.02177 14.9102 9.1311 15.396 9.1311Z"
                        fill="#45A89E"
                      />
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M9.19334 10.2246C6.43347 10.2246 0.924072 11.6896 0.924072 14.5978V17.8777H17.4626V14.5978C17.4626 11.6896 11.9532 10.2246 9.19334 10.2246Z"
                        fill="#45A89E"
                      />
                    </svg>

                    <p>{object.guests}</p>
                  </div>
                ) : null}
                <div />
                <div className="category-price">
                  {object.price}₪
                </div>
                <div className="category-button">
                  <Link
                    to={`/details/${categoryType}/${object.id}`}
                    state={{ category: object }}
                  >
                    <button
                      onClick={handleClick}
                      style={{
                        backgroundColor: isClicked ? "#45A89E" : "",
                        color: isClicked ? "white" : "",
                      }}>طلب عرض سعر</button>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default CategoryPage;
