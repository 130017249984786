import React from "react";
import NavElement from "./NavElement";

class FooterNav extends React.Component {
    render() {
        return (
            <div className="nav-elements">
                <NavElement navText=" لماذا زفاف " scrollTo="/#why" />
                <NavElement navText=" تصنيفات زفاف " scrollTo="/#categories" />
                <NavElement navText=" صاحب مشروع؟ " scrollTo="/#business" />
            </div>
        );
    }
}

export default FooterNav;