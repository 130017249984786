import React from "react";
import NavbarLandingPage from "../landingPage/NavbarLandingPage";
import BlogSection from "./BlogSection";
import Footer from "../Footer";

const Blog = () => {
  return (
    <div>
      <div className="landing-page">
        <NavbarLandingPage />
        <BlogSection />
      </div>
      <Footer />
    </div>
  );
};

export default Blog;
