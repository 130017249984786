import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFacebookF, faInstagram, faLinkedin } from '@fortawesome/free-brands-svg-icons'
import { library } from "@fortawesome/fontawesome-svg-core";

library.add(faFacebookF, faInstagram, faLinkedin);

class SMIcon extends React.Component {
    render() {
        return (
            <a href={this.props.url}>
                <div className="social-media-icons">
                    <div className="sm-icon-wrapper">
                        <FontAwesomeIcon icon={this.props.iconName} />
                    </div>
                </div>
            </a>
        );
    }
}

export default SMIcon