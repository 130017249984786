import React from "react";

const PriceForm = (props) => {
    return (
        <div className="price-form-page">
            <div className="price-form-under">
                <p>سجل الآن وكن من عائلة زفاف!</p>
            </div>
                <div className="price-form-content">
                <div className="form-content">
                    <p>الاسم الكامل</p>
                    <input placeholder="الاسم الكامل"/>
                </div>
                <div className="form-content">
                    <p>رقم التلفون</p>
                    <input placeholder="رقم التلفون"/>
                </div>
                <div className="form-content">
                    <p>اسم الشركة</p>
                    <input placeholder="اسم الشركة"/>
                </div>
                <div className="form-content">
                    <p>البريد الإلكتروني</p>
                    <input placeholder="البريد الإلكتروني"/>
                </div>
                <div className="form-content">
                    <p>نوع الخدمة المقدمة</p>
                    <select className="select-form-content">
                        <option value="قاعات افراح" defaultValue>قاعات أفراح</option>
                        <option value="توزيعات">توزيعات</option>
                        <option value="DJ">DJ</option>
                    </select>
                </div>
                <div className="form-content">
                    <p>المحافظة</p>
                    <select className="select-form-content">
                        <option value="القدس" defaultValue>القدس</option>
                        <option value="رام الله">رام الله</option>
                        <option value="نابلس">نابلس</option>
                    </select>
                </div>
            </div>
            <div className="acceptance-checkbox">
                <input type="checkbox" id="accept" name="accept" value="accept"/>
                <label htmlFor="accept">من خلال إرسال ومشاركة معلوماتك ، فإنك توافق على
                    <span> شروط الاستخدام </span>
                    و
                    <span> سياسة الخصوصية </span>
                    الخاصة بـشركة زفاف</label>
            </div>
            <button className="share-with-us">اشترك معنا الآن</button>

        </div>


    );
};
export default PriceForm;

