import React from "react";

class ColumnTitle extends React.Component {
    render() {
        return (
            <div className="column-title">
                {this.props.title}
            </div>
        );
    }
}

export default ColumnTitle;