import React from "react";

class Frame extends React.Component {
  render() {
    return (
      <section className="frame-210">
        <div className="title-page">
          <p>لوحة عرسك</p>
        </div>
        <div className="main-frame">
          <div className="first-frame">
            <div>
              <p>
                سيتم تجميع كافة الإعجابات والحجوزات في لوحة خاصة لعرسك ليعطيكم
                فكرة عامة وشاملة عن العرس.
                <br />
                ستتمكنون من تشكيل عرسكم بالطريقة الأنسب والقدرة على التغيير
                ومقارنة الأسعار والتناسق بين الحجوزات.
              </p>
            </div>

            <div className="start-now">
              <a>إبدأ بتشكيل لوحتك الآن</a>
            </div>
          </div>
          <div className="second-frame">
            <div className="large-frame">
              <a>القاعة</a>
            </div>
            <div className="small">
              <div className="small-frame">
                <a>فكرة التزيين</a>
              </div>
              <div className="small-frame">
                <a>المكياج والشعر</a>
              </div>
            </div>
            <div className="medium">
              <div className="medium-top">
                <a>منسق الأغاني</a>
                <a>فرقة الزفة</a>
              </div>
              <div className="medium-bottom">
                <a>استوديو التصوير</a>
              </div>
            </div>
            <div className="small">
              <div className="small-frame">
                <a> دعوات العرس</a>
              </div>
              <div className="small-frame">
                <a> تذكارات وتوزيعات</a>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default Frame;
