import React, { useEffect, useState } from "react";
import axios from "axios";
import { useLocation, useParams } from "react-router-dom";
import loadingspiner from "../landingPage/images/icons8-rhombus-loader.gif";

const ArticleSection = () => {
  const location = useLocation();
  const [article, setArticle] = useState(location.state?.blog);
  const { id } = useParams();
  useEffect(() => {
    if (!article) {
      axios({
        method: "get",
        url: `https://us-central1-zifaf-app.cloudfunctions.net/webapp/api/article/${id}`,
        responseType: "json",
      })
        .then(function (response) {
          setArticle(response.data);
        })
        .catch((e) => console.log);
    }
  }, []);
  if (article == null || Object.keys(article).length === 0) {
    return (
      <div className="spinner-loading">
        <img src={loadingspiner} />
      </div>
    );
  }
  return (
    <div className="article">
      <div
        className="blog-header"
        style={{ backgroundImage: `url("${article.articleImage}")` }}
      ></div>
      <div className="Article-section">
        <h3>{article.articleTitle}</h3>
        <p>{article.articleSubtitle}</p>
        <div className="article-cards">
          {article.tags.map((object, index) => {
            return (
                <div key={index} className="article-text">
                  <p>{object}</p>
                </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default ArticleSection;
