import React from "react";
import NavbarLandingPage from "../landingPage/NavbarLandingPage";
import Price from "./Price";
import Footer from "../Footer";
import PriceForm from "./PriceForm";

const PricingUp = (props) => {
    return (
        <div className="price-page">
            <div className="price-page-first-row">
                <div className="price-side">
                <div className="price-right-side">
                    <div className="price-right-side-title">
                        <span>     كل ما تحتاجه أعمال الزفاف الخاصة بك
                        </span>
                        <span> لتزدهر</span>
                    </div>
                    <div className="price-form-title">
                        <p>توفر زفاف للبائعين حلولًا وموارد إعلانية مصممة لتسهيل إدارة أعمال الزفاف.</p>
                    </div>
                    {/*<div className="price-form">*/}
                    {/*    <PriceForm/>*/}
                    {/*</div>*/}
                </div>
                </div>
                <div className="price-left-side">
                </div>
            </div>
            <div className="price-page-second-row price-side">

                <div className="show-on ">
                    <p>ظهرت على</p>
                    <div className="show-on-img">
                        <img src="/images/image 96.png"/>
                        <img src="/images/image 97.png"/>
                        <img src="/images/SWB LOGO.png"/>
                        <img src="/images/image 98.png"/>
                        <img src="/images/image 99.png"/>
                    </div>

                </div>

            </div>
            <div className="show-on-all-par">
                <div className="show-on-par">
                    <p>الظهور بين آلاف العرسان الذين يخططون الآن.</p>
                    <p>المزيد من العملاء المحتملين الذين يتحولون إلى حجوزات</p>
                    <p>تسويق خاص ومستحدث وبأسعار منافسة للسوق</p>
                </div>
            </div>
            <div className="price-side">
            <div className="all-services">
                <div className="service reverse with-line">
                    <div className="service-par-side">
                        <p>سوقك صار أون لاين</p>
                        <p>لأول مرة في فلسطين, سوق الأفراح والمناسبات أون لاين, أنشئ لمصلحلتك التجارية وجود رقمي أنيق
                            وسهل الوصول</p>
                    </div>
                    <div className="service-img-side">
                        {/*<img src="/images/image 111.png"/>*/}
                    </div>
                </div>

                <div className="service with-line">
                    <div className="service-img-side-2">
                    </div>
                    <div className="service-par-side">
                        <p>جمهورنا جمهوركم</p>
                        <p>100% من جمهور زفاف سيكون من عرسان في رحلة البحث عن خدماتكم ومنشآتكم التجارية بكافة
                            أنواعها.</p>
                    </div>
                </div>

                <div className="service reverse">
                    <div className="service-par-side">
                        <p>تسويق سهل وسريع</p>
                        <p>زفاف تملك مجموعة من قنوات التسويق المستحدثة والتي بمجرد اشتراكك معنا ستحصل عليها. </p>
                    </div>
                    <div className="service-img-side-3">
                    </div>
                </div>
            </div>
            </div>


        </div>

    );
};
export default PricingUp;
