import React from "react";

class JoinCta extends React.Component {
    render() {
        return (
            <a href="/join">
                <div className="crm-cta shadow-lg">
                    سجل معنا ببلاش!
                </div>
            </a>
        );
    }
}

export default JoinCta;