import React from "react";

class NavElement extends React.Component {
    render() {
        return (
            <div className="nav-elem">
                <a href={this.props.scrollTo}>
                    {this.props.navText}
                </a>
            </div>
        );
    }
}

export default NavElement;