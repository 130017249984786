import React from "react";
import ValueCard from './ValueCard'

class ValueCompositions extends React.Component {
    

    render() {
        return (
            <div className="cards-wrapper">
                <ValueCard valueText="فش داعي لف عالقاعات لأنه الحجوزات معنا أون لاين" valueImage="images/why-1.png" />
                <ValueCard valueText="مش عارفة وين تروحي؟ الاختيارات
بزفاف واضحة وشاملة" valueImage="images/why-2.png" />
                <ValueCard valueText="من ناحية تكاليف فش قلق
التطبيق مجانا للكل ودايما!!" valueImage="images/why-3.png" />
            </div>
        );
    }
}

export default ValueCompositions;