import NavbarLandingPage from "../landingPage/NavbarLandingPage";
import Footer from "../Footer";
import ArticleSection from "./ArticleSection";

const Article = () => {
  return (
    <div>
      <div className="landing-page">
        <NavbarLandingPage />
        <ArticleSection />
      </div>
      <Footer />
    </div>
  );
};

export default Article;
