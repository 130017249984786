import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import { faCopyright } from "@fortawesome/free-solid-svg-icons";

library.add(faCopyright);

class Copyrights extends React.Component {
    render() {
        return (
            <div className="copyright-banner mt-5 mb-3">
                <div className="copyright-label">
                    جميع الحقوق محفوظة لشركة زفاف<FontAwesomeIcon icon="fa-copyright" /> {new Date().getFullYear()}
                </div>
                {/* <div className="copyright-label">
                    تصميم وتطوير -
                    <svg width="20" height="18" viewBox="0 0 20 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M9.55421 0.28125L6.88477 6.04163L10.3269 13.8392C10.9451 16.4244 9.27322 17.4922 8.35999 17.7029H18.7568C16.9022 16.6351 15.7829 14.6822 15.4551 13.8392L9.55421 0.28125Z" fill="#45a89e" />
                        <path d="M5.47756 17.7024C3.5106 17.309 3.25303 15.2437 3.37011 14.2603C2.63952 16.3396 0.911406 17.4214 0.138672 17.7024H5.47756Z" fill="#45a89e" />
                        <path d="M9.55421 0.28125L6.88477 6.04163L10.3269 13.8392C10.9451 16.4244 9.27322 17.4922 8.35999 17.7029H18.7568C16.9022 16.6351 15.7829 14.6822 15.4551 13.8392L9.55421 0.28125Z" stroke="#45a89e" strokeWidth="0.140497" />
                        <path d="M5.47756 17.7024C3.5106 17.309 3.25303 15.2437 3.37011 14.2603C2.63952 16.3396 0.911406 17.4214 0.138672 17.7024H5.47756Z" stroke="#45a89e" strokeWidth="0.140497" />
                    </svg>

                </div> */}
            </div>
        );
    }
}

export default Copyrights