import React from "react";
import Logo from "./Logo";
import Footer from "./Footer";
import NavbarLandingPage from "./landingPage/NavbarLandingPage";
import db from '../firebase';
import { collection, addDoc } from "firebase/firestore";
const initialFormData = Object.freeze({
    firstname: "",
    email: "",
    phone_number: "",
    business_name: "",
    business_type: "",
    areaOfWork: "",
});



const Pricing = () => {
    const [formData, updateFormData] = React.useState(initialFormData);
    const handleChange = (e) => {
        updateFormData({
            ...formData,
            [e.target.name]: e.target.value.trim()
        });
    };

    let pushTicket = async (e) => {
        e.preventDefault();
        for (const key in formData) {
            if (formData[key] == "" || formData[key] == 0 || formData[key] == undefined) {
                alert('الرجاء تعبئة جميع الخانات');
                return;
            }
        }

        const collectionRef = collection(db, "Pending");
        const payload = {
            ticketType: "landing",
            accepted: true,
            acceptedDate: "",
            businessId: "ORSqLlzrEGeDrILIk4USvrYrQ1p2",
            businessType: formData.business_name,
            businessName: formData.business_type,
            creationDate: new Date(),
            date: "2022-07-29",
            documentID: "",
            is_notified_paid: false,
            is_paid: false,
            name: formData.firstname,
            paid_amount: 0,
            pending: false,
            phone: formData.phone_number,
            email: formData.email,
            productId: "",
            quantity: "300",
            residency: "رام الله",
            timeRunOut: false,
            typeOfEvent: "BUSINESS REG",
            userUid: "",
            zifaf_dues: 0,
            source: "LANDING SALES PAGE",
            location: formData.areaOfWork
        }

        addDoc(collectionRef, payload);
        alert("شكرا لاختياركم زفاف، سيتم التواصل معكم في الأيام القليلة القادمة")
    }

    return (
        <div>
            {/* <div className="m-5">
                <Logo color="black" />
            </div> */}
            <div className="priotity-max shadow" style={{width: "100%", backgroundColor: "white"}}>
                <div className="container">
                    <NavbarLandingPage />
                </div>
            </div>

            <div className="camp-header-wrapper">
                <div className="header justify-content-start">
                    <div className="camp-header position-absolute col-lg-6 col-md-6 col-sm-12 col-xs-12 "></div>
                </div>

                <div className="container uvp position-relative" style={{ "height": "65vh", "backgroundImage": "none" }}>
                    <div className="slogan col-lg-6 col-md-6 col-sm-12 col-xs-12">
                        <div className="slogan-text mb-5 pb-5 mt-5" style={{ "color": "black", "fontSize": "30px", "width": "90%" }}>
                            أهلا وسهلا بك في أول منصة فلسطينية تضع سوق الأفراح والمناسبات في تطبيق واحد بين أيدي عملائكم،
                        </div>

                        <a href="#letter_form">
                            <div className="slogan-subtext shadow">
                                للتسجيل
                            </div>
                        </a>
                    </div>
                </div>
            </div>
            <div className="container">
                <div className="row camp-reasons">
                    <div className="camp-card col-lg-4 col-md-12 col-sm-12 col-xs-12 ">
                        <div className="reason-number">01</div>
                        <div className="reason-title" style={{ backgroundImage: "url(images/tanzeem.png)" }}>
                        </div>
                        <div className="reason-text shadow p-3">
                            تساعدك زفاف على تنظيم طلبات الحجوزات والشراء لديك من خلال لوحة الكترونية سهلة الاستخدام
                        </div>
                    </div>
                    <div className="camp-card col-lg-4 col-md-12 col-sm-12 col-xs-12">
                        <div className="reason-number">02</div>
                        <div className="reason-title" style={{ backgroundImage: "url(images/tasweeq.png)" }}>
                        </div>
                        <div className="reason-text shadow p-3">
                            من أهم المجالات التي تعمد عليها زفاف هي اظهار منتجك بأبهى صورة بأفل التكاليف
                        </div>
                    </div>
                    <div className="camp-card  col-lg-4 col-md-12 col-sm-12 col-xs-12">
                        <div className="reason-number">03</div>
                        <div className="reason-title" style={{ backgroundImage: "url(images/tahseen.png)" }}>
                        </div>
                        <div className="reason-text shadow p-3">
                            زفاف ستمنحك معلومات عن زبائنك المستهدفين للاستدلال بها لتطوير وتحسين الخدمة المقدمة
                        </div>
                    </div>
                </div>

            </div>
            <div className="crm-section">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320">
                    <g filter="url(#filter0_d_907_8)">
                        <path fill="#fff9e6" fillOpacity="1"
                            d="M0,224L48,224C96,224,192,224,288,240C384,256,480,288,576,266.7C672,245,768,171,864,138.7C960,107,1056,117,1152,149.3C1248,181,1344,235,1392,261.3L1440,288L1440,320L1392,320C1344,320,1248,320,1152,320C1056,320,960,320,864,320C768,320,672,320,576,320C480,320,384,320,288,320C192,320,96,320,48,320L0,320Z">
                        </path>
                    </g>
                    <defs>
                        <filter id="filter0_d_907_8" x="0" y="0" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                            <feFlood floodOpacity="0" result="  " />
                            <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                            <feOffset dy="-14" />
                            <feGaussianBlur stdDeviation="12" />
                            <feComposite in2="hardAlpha" operator="out" />
                            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0" />
                            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_907_8" />
                            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_907_8" result="shape" />
                        </filter>
                    </defs>
                </svg>

                <div className="container">
                    <div className="section-title w-100">
                        <div className="coin-anim">
                            <img src="images/coin-anim.gif" alt="" />
                        </div>
                        <span className="font-weight-bold">
                            لوحة إدارة المحتوى
                        </span>

                    </div>
                    <div className="section-subtitle w-100">
                        لأول مرة، لوحة إدارة لكل الطلبات والحجوزات، القلق بالطلبيات وتفاصيلها صار من الماضي
                    </div>
                    <div className="crm-image shadow">
                        <img src="images/zifafCRM_demo.jpg" alt="" />
                    </div>
                </div>


                <svg className="crm-bottom-wave" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320">
                    <g filter="url(#filter0_d_907_8)">
                        <path fill="#fff9e6" fillOpacity="1"
                            d="M0,224L48,224C96,224,192,224,288,240C384,256,480,288,576,266.7C672,245,768,171,864,138.7C960,107,1056,117,1152,149.3C1248,181,1344,235,1392,261.3L1440,288L1440,320L1392,320C1344,320,1248,320,1152,320C1056,320,960,320,864,320C768,320,672,320,576,320C480,320,384,320,288,320C192,320,96,320,48,320L0,320Z">
                        </path>
                    </g>
                    <defs>
                        <filter id="filter0_d_907_8" x="0" y="0" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                            <feFlood floodOpacity="0" result="  " />
                            <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                            <feOffset dy="-14" />
                            <feGaussianBlur stdDeviation="12" />
                            <feComposite in2="hardAlpha" operator="out" />
                            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0" />
                            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_907_8" />
                            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_907_8" result="shape" />
                        </filter>
                    </defs>
                </svg>
            </div>

            <div className="news_letter w-100" id="newsLetter">
                <div className="container">
                    <div className="section-title w-100">
                        <div className="coin-anim">
                            <img src="images/edit-anim.gif" alt="" />
                        </div>
                        <span className="font-weight-bold">
                            سجل معنا الآن !
                        </span>
                    </div>
                    <div className="form-wrapper">
                        <div className="form-image">
                            <img src="https://images.unsplash.com/photo-1533091090875-1ff4acc497dd?crop=entropy&cs=tinysrgb&fm=jpg&ixlib=rb-1.2.1&q=80&raw_url=true&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1469" alt="" />
                        </div>
                        <form>
                            <div className="letter_form" id="letter_form">
                                <div className="inputs-holder">
                                    <div className="form-part">
                                        <input onChange={handleChange} type="text" placeholder="الاسم الشخصي" name="firstname" required />
                                        <select onChange={handleChange} id="areaOfWork" className="" name="areaOfWork">
                                            {/* <option>اختر منطقة العمل</option> */}
                                            <option value="القدس">
                                                القدس
                                            </option>

                                            <option value="رام الله">
                                                رام الله
                                            </option>
                                            <option value="الخليل">
                                                الخليل
                                            </option>

                                            <option value="نابلس">
                                                نابلس
                                            </option>

                                            <option value="الرام">
                                                الرام
                                            </option>

                                            <option value="الخليل">
                                                الخليل
                                            </option>

                                            <option value="الطيبة">
                                                الطيبة
                                            </option>

                                            <option value="حيفا">
                                                حيفا
                                            </option>

                                            <option value="يافا">
                                                يافا
                                            </option>

                                            <option value="عكا">
                                                عكا
                                            </option>

                                            <option value="طولكرم">
                                                طولكرم
                                            </option>

                                            <option value="جنين">
                                                جنين
                                            </option>

                                            <option value="العيزرية">
                                                العيزرية
                                            </option>

                                        </select>

                                        <select onChange={handleChange} id="business_type" className="" name="business_type">
                                            {/* <option selected> نوع الخدمة المقدمة</option> */}
                                            <option value="قاعات أفراح">
                                                قاعات أفراح
                                            </option>

                                            <option value="فساتين أفراح">
                                                فساتين أفراح
                                            </option>
                                            <option value="جلسات تصوير">
                                                جلسات تصوير
                                            </option>

                                            <option value="تجميل">
                                                تجميل
                                            </option>

                                            <option value="فرق زفات">
                                                فرق زفات
                                            </option>

                                            <option value="توزيعات">
                                                توزيعات
                                            </option>
                                            <option value="دي جيه">
                                                دي جيه
                                            </option>
                                            <option value="إكسسوارات">
                                                إكسسوارات
                                            </option>

                                            <option value="زينة">
                                                زينة
                                            </option>
                                        </select>
                                    </div>
                                    <div className="form-part">
                                        <input onChange={handleChange} type="text" placeholder="إسم الخدمة" name="business_name" required />
                                        <input onChange={handleChange} type="text" placeholder="رقم الهاتف" name="phone_number" required />
                                        <input onChange={handleChange} type="text" placeholder="البريد الالكتروني " name="email" required />
                                    </div>
                                </div>
                                <button onClick={pushTicket} type="button" className="letter_submit ml-2 shadow">أرسل</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            <Footer />

        </div >
    );
}

export default Pricing