import React from "react";
class FooterWave extends React.Component {
    render() {
        return (
            <svg className="footer-svg" viewBox="0 0 1920 299" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g filter="url(#filter0_i_770_3)">
                    <path d="M810.6 198.821C655.333 198.421 500.067 270.332 344.8 291.24C214.612 309.44 84.4237 291.783 0 277.518V0H344.8H810.6H1276.4H1742.2H1920V207.297C1863.84 196.084 1803.02 189.155 1742.2 191.763C1586.93 198.421 1431.67 270.332 1276.4 269.933C1121.13 270.332 965.867 198.421 810.6 198.821Z" fill="#FFFFFF" />
                </g>
                <defs>
                    <filter id="filter0_i_770_3" x="0" y="-32" width="1920" height="331" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                        <feFlood floodOpacity="0" result="BackgroundImageFix" />
                        <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
                        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                        <feOffset dy="-32" />
                        <feGaussianBlur stdDeviation="18" />
                        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
                        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0" />
                        <feBlend mode="normal" in2="shape" result="effect1_innerShadow_770_3" />
                    </filter>
                </defs>
            </svg>
        );
    }
}

export default FooterWave;