import React from "react";
import { Link } from "react-router-dom";

const StoriesCard = (props) => {
  const firstBlog = props.blog;

  return (
    <div
      className="blog-header stories-article"
      style={{ backgroundImage: `url("${firstBlog.articleImage}")` }}
    >
      <div className="blog-header-container">
        <div className="blog-header-title">
          <p>{firstBlog.articleTitle}</p>
        </div>
        <div className="blog-header-paragraph">
          <p>{firstBlog.articleSubtitle}</p>
          <div className="block-header-svg">
            <Link to="/blog">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="64"
                height="38"
                viewBox="0 0 64 38"
                fill="none"
              >
                <line
                  x1="64"
                  y1="19"
                  x2="-8.74228e-08"
                  y2="19"
                  stroke="white"
                  strokeWidth="2"
                />
                <line
                  x1="23.6163"
                  y1="0.787505"
                  x2="0.616309"
                  y2="18.7875"
                  stroke="white"
                  strokeWidth="2"
                />
                <line
                  y1="-1"
                  x2="29.2062"
                  y2="-1"
                  transform="matrix(-0.787505 -0.616308 -0.616308 0.787505 23 38)"
                  stroke="white"
                  strokeWidth="2"
                />
              </svg>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default StoriesCard;
