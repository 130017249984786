import React from 'react';

class Logo extends React.Component {

    render() {
        return (
            <a href="/">
                <div className="white-logo">
                    <svg viewBox="0 0 164 188" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M152.183 73.5625L141.034 67.5899L136.455 78.3406L147.106 84.3132L152.183 73.5625Z"
                            fill={this.props.color} />
                        <path d="M124.41 68.2867L113.56 62.9113L108.383 73.5625L119.433 78.9378L124.41 68.2867Z"
                            fill={this.props.color} />
                        <path d="M152.183 73.5625L141.034 67.5899L136.455 78.3406L147.106 84.3132L152.183 73.5625Z"
                            fill={this.props.color} />
                        <path d="M124.41 68.2867L113.56 62.9113L108.383 73.5625L119.433 78.9378L124.41 68.2867Z"
                            fill={this.props.color} />
                        <path fillRule="evenodd" clipRule="evenodd"
                            d="M123.533 95.7607C108.721 100.469 105.596 127.216 123.414 124.131C136.713 122.379 141.186 127.216 139.839 130.302C135.061 141.252 101.814 149.415 101.814 124.131C99.4245 79.7342 96.3386 37.1296 91.9587 22.4967L87.3797 33.8447C91.1624 42.9031 94.7128 102.43 96.3386 130.7C99.0263 167.233 142.427 151.405 142.427 130.7C143.423 115.371 139.341 105.495 132.772 99.5433C129.805 96.8557 126.221 94.9063 123.533 95.7607ZM126.727 112.273C128.178 112.125 129.53 111.986 130.582 111.986C124.609 103.426 116.247 106.91 116.944 111.389C118.525 113.114 122.96 112.659 126.727 112.273Z"
                            fill={this.props.color} />
                        <path fillRule="evenodd" clipRule="evenodd"
                            d="M162.431 111.19L149.69 94.5661L147.902 106.81C149.495 108.323 149.959 109.299 149.993 109.597C151.346 117.322 156.065 126.885 158.255 130.7C159.054 131.815 158.92 132.89 158.752 133.289C138.047 160.862 119.532 174.898 101.814 176.291C73.7024 174.778 77.6245 144.072 83.0993 128.909C89.1516 125.5 91.3283 117.6 91.6601 114.077C90.7938 89.7231 83.5977 84.2654 78.1732 80.1513L78.17 80.1489L78.1633 80.1438C77.9816 80.006 77.8123 79.8548 77.6245 79.7342C73.6384 77.1744 70.0549 77.7739 68.0683 79.7342C53.0372 94.5661 61.9961 107.109 68.0683 107.109H83.0993C90.6841 107.109 89.8019 113.048 88.6738 114.077C60.3039 134.284 -11.9647 144.139 7.14769 95.9597C14.5531 96.654 15.8611 89.6966 16.6307 85.6032C16.8542 84.4144 17.0323 83.4672 17.3011 83.0191C17.3808 81.5857 16.8034 82.2891 16.5048 82.82C12.3239 94.168 1.77234 85.4082 4.16138 76.4493C4.16138 74.6731 6.3864 67.8 8.51499 61.2248C10.5616 54.9028 12.5191 48.8561 12.3239 47.8802C12.2443 46.5264 11.8926 46.9843 11.7267 47.3825C7.66531 59.1684 4.65908 65.8976 3.66363 67.7889C-2.60757 81.9241 0.377759 92.9734 5.25539 94.8647C5.9721 95.2629 5.94981 95.7606 5.84908 95.9597C-22.0196 157.975 57.5156 147.324 80.3076 130.103C81.1836 129.227 81.3363 129.871 81.3031 130.302C63.0867 178.083 100.814 221.584 155.563 150.709C164.402 140.754 164.113 129.108 162.73 124.728C161.661 121.343 158.98 113.28 157.255 109.597L162.431 111.19ZM75.6616 95.4506C77.1123 95.3019 78.4641 95.1634 79.5158 95.1634C73.5432 86.6027 65.1815 90.0867 65.8783 94.5661C67.4594 96.291 71.8943 95.8366 75.6616 95.4506Z"
                            fill={this.props.color} />
                        <path d="M50.9468 61.7168L62.1952 67.2913L67.2719 56.6401L56.1231 50.767L50.9468 61.7168Z"
                            fill={this.props.color} />
                        <path
                            d="M90.9949 8.65446C90.9949 8.65446 88.035 2.21791 95.853 0.69574C95.853 0.69574 97.9684 6.28172 90.9949 8.65446Z"
                            fill="#FFC61B" />
                        <path
                            d="M90.957 8.54778C90.957 8.54778 95.6382 3.24934 100.14 9.86686C100.14 9.85881 95.8976 14.0413 90.957 8.54778Z"
                            fill="#FFC61B" />
                        <path
                            d="M90.699 8.41925C90.699 8.41925 94.2883 14.526 86.6614 16.8324C86.6614 16.8384 83.9971 11.4877 90.699 8.41925Z"
                            fill="#FFC61B" />
                        <path
                            d="M91.4397 7.88684C91.4397 7.88684 88.184 14.1612 82.2103 8.82838C82.2103 8.82838 85.3172 3.74755 91.4397 7.88684Z"
                            fill="#FFC61B" />
                        <path
                            d="M90.6491 8.66049C90.6491 8.66049 83.748 7.24489 87.1644 0C87.1704 0 92.8871 1.59657 90.6491 8.66049Z"
                            fill="#FFC61B" />
                        <path
                            d="M90.7592 8.7731C90.7592 8.7731 97.801 8.6987 95.9631 16.5066C95.9712 16.5066 90.0434 16.1527 90.7592 8.7731Z"
                            fill="#FFC61B" />
                    </svg>
                </div>
            </a>
        )
    }
}

export default Logo;