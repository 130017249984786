import React from "react";

class SectionTitle extends React.Component {

    render() {
        return (
            <div id={this.props.id} className="title">
                {this.props.title}
                <div className="title-extension">
                    <img src={this.props.extension} alt="" />
                </div>
            </div>
        );
    }
}
export default SectionTitle