import React from "react";
import NavbarLandingPage from "../landingPage/NavbarLandingPage";
import Footer from "../Footer";
import LoginSection from "./LoginSection";
import {useParams} from "react-router-dom";

const Login = () => {
    const data = useParams();

    return (
    <div>
      <div className="landing-page">
        <NavbarLandingPage />
        <LoginSection id={data.id} type={data.type} />
      </div>
      <Footer />
    </div>
  );
};

export default Login;
