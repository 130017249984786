import React from "react";
class HeaderWave extends React.Component {
    render() {
        return (
            <svg className='upper-vector-wave' viewBox="0 0 3763 541" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g filter="url(#filter0_i_733_4)">
                    <path d="M3762.79 6.4482L3637.37 28.7864C3511.94 50.2896 3261.09 96.2187 3010.23 117.722C2759.38 140.06 2508.53 140.06 2257.68 95.5924C2006.82 50.2896 1755.97 -37.3932 1505.12 17.513C1254.26 73.2542 1003.41 273.672 752.558 307.075C501.705 340.478 250.852 206.866 125.426 140.06L-0.000724792 73.2542V540.896H125.426C250.852 540.896 501.705 540.896 752.558 540.896C1003.41 540.896 1254.26 540.896 1505.12 540.896C1755.97 540.896 2006.82 540.896 2257.68 540.896C2508.53 540.896 2759.38 540.896 3010.23 540.896C3261.09 540.896 3511.94 540.896 3637.37 540.896H3762.79V6.4482Z" fill="#FFFFFF" />
                </g>
                <defs>
                    <filter id="filter0_i_733_4" x="0" y="0" width="3762.79" height="580.896" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                        <feFlood floodOpacity="0" result="BackgroundImageFix" />
                        <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
                        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                        <feOffset dy="40" />
                        <feGaussianBlur stdDeviation="20" />
                        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
                        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0" />
                        <feBlend mode="normal" in2="shape" result="effect1_innerShadow_733_4" />
                    </filter>
                </defs>
            </svg>
        );
    }
}

export default HeaderWave;