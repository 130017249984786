import React from "react";
import Category from "./Category";
import photo from "./images/Rectangle 40.png";

class HeaderSection extends React.Component {
    render() {
        return (
            <div className="head-landing">
                <div className="image-header"
                     style={{backgroundImage: `url("${photo}")`}}

                >
                    <div className="paragraph-section">
                        <p> فلنبنِ حفلة زفافك معاً من الألفِ إلى الياء.</p>
                    </div>
                </div>
                <Category/>
                <div className="arrow-down">
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="67"
                        height="67"
                        viewBox="0 0 67 67"
                        fill="none"
                    >
                        <g clipPath="url(#clip0_365_1678)">
                            <path
                                d="M19.3314 27.6162L33.1396 41.4244L46.9477 27.6162H19.3314Z"
                                fill="#FFC61B"
                            />
                        </g>
                        <defs>
                            <clipPath id="clip0_365_1678">
                                <rect width="66.2791" height="66.2791" fill="white"/>
                            </clipPath>
                        </defs>
                    </svg>
                </div>
            </div>
        );
    }
}

export default HeaderSection;
