import React from 'react';
import DownloadNow from './DownloadNow';
import HeaderWave from './HeaderWave';
import Navbar from './Navbar';

class HeaderSection extends React.Component {
    render() {
        return (
            <div className="header-section">
                <Navbar />
                <div className="header-content">
                    <div className="slogan-wrapper">
                        <div className="slogan">
                            تنظيم الأعراس مسؤولية،
                            فريق زفاف راح يحملها معك،
                            بكل تفاصيلها.
                        </div>

                        <DownloadNow downloadClass="download-now" />
                    </div>
                    <div className="mockup">
                        <img src="images/samsung_mockup.png" alt="mockup" />
                    </div>
                </div>
                <HeaderWave />
            </div>
        )
    }
}

export default HeaderSection