// import React from "react";
// import { StrictMode } from 'react';
// import ReactDOM from 'react-dom';

// import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';

// import { createRoot } from 'react-dom/client';
// import App from './components/App';

// const rootElement = document.getElementById('root');
// const root = createRoot(rootElement);

// root.render(
//     <Router>
//         <Switch>
//             <Route exact path="/" component={App} />
//             {/* <Route exact path="/about" component={Pricing}/> */}
//         </Switch>
//     </Router>
// );

import React from "react";
import { createRoot } from "react-dom/client";
import { Route, Routes, BrowserRouter } from "react-router-dom";
import App from "./components/App";
import Pricing from "./components/Pricing";
import LandingPage from "./components/landingPage/LandingPage";
import Services from "./components/landingPage/CategoriesFrames/allCategory/Services";
import ServicesDetails from "./components/landingPage/CategoriesFrames/allCategory/ServicesDetails";
import Blog from "./components/BlogPage/Blog";
import Article from "./components/ArticlePage/Article";
import Login from "./components/Login/Login";
import ScrollTop from "./ScrollTop";
import PricingDown from "./components/PricingPage/PricingDown";
import PricingPage from "./components/PricingPage/PricingPage";
const rootElement = document.getElementById("root");
const root = createRoot(rootElement);

root.render(
  <>
    <BrowserRouter>
      <ScrollTop>
      <Routes>
        <Route path="/app" element={<App />} />
      </Routes>
      <Routes>
        <Route path="/join" element={<PricingPage />} />
      </Routes>
      <Routes>
        <Route path="/" element={<LandingPage />} />
      </Routes>
      <Routes>
        <Route path="/services/:type" element={<Services />} />
      </Routes>
      <Routes>
        <Route path="/details/:type/:id" element={<ServicesDetails />} />
      </Routes>
      <Routes>
        <Route path="/blog" element={<Blog />} />
      </Routes>
      <Routes>
        <Route path="/article/:id" element={<Article />} />
      </Routes>
      <Routes>
        <Route path="/login/:type/:id" element={<Login />} />
      </Routes>
        <Routes>
          <Route path="/price" element={<PricingDown />} />
        </Routes>
      </ScrollTop>
    </BrowserRouter>
  </>
);
