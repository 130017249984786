import React from "react";
import SMIcon from './SMIcon';


class SocialMediaIcons extends React.Component {
    render() {
        return (
            <div className="icons-container">
                <SMIcon iconName="fa-brands fa-facebook-f" url="https://www.facebook.com/zifafapp" />
                <SMIcon iconName="fa-brands fa-instagram" url="https://www.instagram.com/_u/zifafapp" />
                <SMIcon iconName="fa-brands fa-linkedin" url="https://www.linkedin.com/company/zifaf" />
            </div>

        );
    }
}
export default SocialMediaIcons