import React from "react";

class LandingPageNavbarElement extends React.Component {
  render() {
    return (
      <div className="nav-landing-item">
        <a href={this.props.scrollTo}>{this.props.navText}</a>
      </div>
    );
  }
}

export default LandingPageNavbarElement;
