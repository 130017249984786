import React from "react";

class DownloadNow extends React.Component {

    getMobileOperatingSystem = () => {
        var userAgent = navigator.userAgent || navigator.vendor || window.opera;

        // Windows Phone must come first because its UA also contains "Android"
        if (/windows phone/i.test(userAgent)) {
            console.log('windows but redirect to android')
            return "https://play.google.com/store/apps/details?id=com.zifaf.app";
        }

        if (/android/i.test(userAgent)) {
            return "https://play.google.com/store/apps/details?id=com.zifaf.app";
        }

        // iOS detection from: http://stackoverflow.com/a/9039885/177710
        if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
            return "https://apps.apple.com/il/app/zifaf/id1579058899";
        }

        return "https://play.google.com/store/apps/details?id=com.zifaf.app";
    }

    redirectToStore = () => {
        console.log(this.getMobileOperatingSystem());
        window.location.href = this.getMobileOperatingSystem();
    }

    

    render() {
        return (
            <div className={this.props.downloadClass} onClick={this.redirectToStore}>
                حمله الآن
            </div>
        );
    }
}

export default DownloadNow;