import React from "react";
import OwlCarousel from "react-owl-carousel-rtl";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import StoriesCard from "./StoriesCard";

const Stories = (props) => {
  return (
    <div className="stories-categories">
      <div className="story">{props.story}</div>
      <div>
        <OwlCarousel
          className="owl-theme owl-carousel"
          nav={false}
          loop={false}
          margin={10}
          responsive={{
            0: {
              items: 1
            },
            600: {
              items: 2
            },
            1000: {
              items: 3
            }
          }}

        >
          {props.blog.slice(0, 5).map((object, index) => (
            <StoriesCard key={index} blog={object} />
          ))}
        </OwlCarousel>
      </div>
    </div>
  );
};
export default Stories;
