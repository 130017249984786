import React from "react";
import styled from 'styled-components'


class CategoryCard extends React.Component {

    render() {
        let CategoryWrapper = styled.div`
            background-color: white;
            width: 250px;
            height: 300px;
            border-radius: 50px;
            background-image: url(${this.props.imageUrl});
            background-size: cover;
            display: flex; 
            align-items: flex-end;
            margin: 30px;
        `

        let CategoryTitle = styled.div`
            width: 100%;
            height: 50px;
            background-color: white;
            border-radius: 0 0 47px 47px;
            color: var(--background-color2);
            font-weight: bold;
            font-size: 20px;
            text-align: center;
            padding-top: 10px;
        `
        return (
            <CategoryWrapper className="shadow category-card">
                <CategoryTitle>
                    {this.props.categoryName}
                </CategoryTitle>
            </CategoryWrapper>
        )
    }
}

export default CategoryCard;