import React, { useEffect, useState } from "react";
import axios from "axios";
import { collectData } from "../../utilities";
import {Link, useNavigate} from "react-router-dom";
import OwlCarousel from "react-owl-carousel-rtl";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";

const InvitationCardsBlock  = () => {
  const [invitations, setInvitations] = useState(null);
  const categoryType = "Invitations";
  useEffect(() => {
    axios({
      method: "get",
      url: `https://us-central1-zifaf-app.cloudfunctions.net/webapp/api/Invitations`,
      responseType: "json",
    }).then(function (response) {
      //check categoryType and return data
      const collectionsData = collectData(categoryType, response.data);
      setInvitations(collectionsData);
    });
  }, []);
  const navigate=useNavigate()

  if (invitations == null) {
    return;
  }
  return (
      <section className="invitation-blocks">
        <div className="invitation-title">
          <p>بطاقات دعوة</p>
        </div>
        <div className="invitation-block">
          <div className="invitation-slider">
            <OwlCarousel
                className="owl-theme owl-carousel"
                nav={false}
                loop={false}
                margin={10}
                items={1}
            >
              {invitations.slice(0, 3).map((object, index) => (
                  <div className="item" style={{ width: "100%" }} key={index}>
                    <div className="InvitationCardsWrapper" style={{ backgroundImage: `url("${object.logo}")` }}>
                    </div>
                  </div>
              ))}
            </OwlCarousel>
          </div>
          <div className="cards-category">
            {invitations.slice(3, 8).map((object, index) => {
              return (
                  <div
                      key={index}
                      className="card"
                      style={{ backgroundImage: `url("${object.logo}")` }}
                  >
                    <div className="card-details">
                      <div className="cards-number">{object.name}</div>
                      <div className="cards-price-and-more">
                        <p>{object.price}₪</p>
                        <div className="more">
                          <Link to={`/details/${categoryType}/${object.id}`}>
                            المزيد
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
              );
            })}

            <div className="card all-cards"
                 onClick={((e)=>{navigate(`/services/${categoryType}`)})}>
              شاهد جميع التصاميم
            </div>
          </div>
        </div>
      </section>
  );
};

export default InvitationCardsBlock ;
