import React from "react";
import FooterNav from "./FooterNav";

class FooterForm extends React.Component {
    render() {
        return (
            <div className="footer-form-wrapper">
                <div className="footer-form-label">
                    خلينا نتواصل معك نساعدك بتفاصيل
                    عرسك من الصفر
                </div>
                <div className="phone-input">
                    <input type="tel" placeholder="رقم الهاتف" className="shadow" />
                    <div className="send-number" onClick={(e) => {
                        e.preventDefault();
                        window.location.href = 'https://web.whatsapp.com/send?phone=0586490493';
                    }}>أرسل</div>
                </div>
                <div className="footer-nav-wrapper">
                    <hr color="#ffe38d" />
                    <FooterNav />
                </div>

            </div>
        );

    }
}

export default FooterForm;