import React from "react";
import NavbarLandingPage from "../../NavbarLandingPage";
import Category from "../../Category";
import CategoryPage from "./CategoryPage";
import { useParams } from "react-router-dom";
import Footer from "../../../Footer";

const Services = () => {
  const data = useParams();
  return (
    <div>
      <div className="landing-page">
        <NavbarLandingPage />
        <Category active={data.type} />
        {/* <div className="location-header">
          <div className="right-side">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="27"
              height="36"
              viewBox="0 0 27 36"
              fill="none"
            >
              <path
                d="M13.0737 0.268555C20.1227 0.268555 25.8237 5.96963 25.8237 13.0186C25.8237 20.6139 17.773 31.0871 14.458 35.0761C13.7294 35.9503 12.3998 35.9503 11.6712 35.0761C8.37444 31.0871 0.32373 20.6139 0.32373 13.0186C0.32373 5.96963 6.0248 0.268555 13.0737 0.268555ZM13.0737 17.5721C15.5873 17.5721 17.6273 15.5321 17.6273 13.0186C17.6273 10.505 15.5873 8.46498 13.0737 8.46498C10.5602 8.46498 8.52016 10.505 8.52016 13.0186C8.52016 15.5321 10.5602 17.5721 13.0737 17.5721Z"
                fill="#FFC61B"
              />
            </svg>
            <input type="text" />
          </div>
          <div className="left-side">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="26"
              height="20"
              viewBox="0 0 26 20"
              fill="none"
            >
              <path
                d="M15.8889 19.5C15.8889 19.7761 15.665 20 15.3889 20H10.6111C10.335 20 10.1111 19.7761 10.1111 19.5V17.1667C10.1111 16.8905 10.335 16.6667 10.6111 16.6667H15.3889C15.665 16.6667 15.8889 16.8905 15.8889 17.1667V19.5ZM25.5 0C25.7761 0 26 0.223858 26 0.5V2.83333C26 3.10948 25.7761 3.33333 25.5 3.33333H0.5C0.223858 3.33333 0 3.10948 0 2.83333V0.5C0 0.223857 0.223858 0 0.5 0H25.5ZM21.6667 11.1667C21.6667 11.4428 21.4428 11.6667 21.1667 11.6667H4.83333C4.55719 11.6667 4.33333 11.4428 4.33333 11.1667V8.83333C4.33333 8.55719 4.55719 8.33333 4.83333 8.33333H21.1667C21.4428 8.33333 21.6667 8.55719 21.6667 8.83333V11.1667Z"
                fill="#FFC61B"
              />
            </svg>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
            >
              <path
                d="M6.84734 15.0943H7.93139L8.31561 14.7238C6.97084 13.1595 6.16123 11.1286 6.16123 8.91938C6.16123 3.99314 10.1544 0 15.0806 0C20.0069 0 24 3.99314 24 8.91938C24 13.8456 20.0069 17.8388 15.0806 17.8388C12.8714 17.8388 10.8405 17.0292 9.27616 15.6844L8.90566 16.0686V17.1527L2.0446 24L-1.90735e-06 21.9554L6.84734 15.0943V15.0943ZM15.0806 15.0943C18.4974 15.0943 21.2556 12.3362 21.2556 8.91938C21.2556 5.50257 18.4974 2.74443 15.0806 2.74443C11.6638 2.74443 8.90566 5.50257 8.90566 8.91938C8.90566 12.3362 11.6638 15.0943 15.0806 15.0943Z"
                fill="#FFC61B"
              />
            </svg>
          </div>
        </div> */}
        <CategoryPage active={data.type} />
      </div>
      <div>
        <Footer />
      </div>
    </div>
  );
};
export default Services;
