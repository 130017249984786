import React, { useState } from 'react';
import Logo from './Logo';
import NavElement from "./NavElement";
import DownloadNow from "./DownloadNow";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars } from '@fortawesome/free-solid-svg-icons';
import { library } from "@fortawesome/fontawesome-svg-core";

library.add(faBars);

const Navbar = (props) => {
    let isResponsive = window.innerWidth <= 768 ? false : true;
    const [isOpened, setIsOpened] = useState(isResponsive);

    function toggle() {
        console.log(window.innerWidth);
        setIsOpened(isOpened => !isOpened);
    }

    return (
        <div className="navbar">
            <Logo color="white" />

            <div className="responsive-menubars" onClick={() => toggle()}>
                <FontAwesomeIcon icon="fa-solid fa-bars" />
            </div>
            {isOpened && (
                <div className="nav-elements-wrapper">
                    <div className="nav-elements">
                        <NavElement navText=" لماذا زفاف " scrollTo="#why" />
                        <NavElement navText=" تصنيفات زفاف " scrollTo="#categories" />
                        <NavElement navText=" صاحب مشروع؟ " scrollTo="#business" />
                        <DownloadNow downloadClass="nav-download" />
                    </div>
                </div>
            )}

        </div>
    )
}


export default Navbar;