import React, {useState} from "react";
import Footer from "../Footer";

const Price = () => {
        const plans = {
            yearlyPlan: {
                mainPlan1: {
                    "name": "الأساسية",
                    "price": "250",
                    "oldPrice": "",
                    "data": [
                        {"content": "ظهور لمنشأتك التجارية في تطبيق على الهواتف الذكية"},
                        {"content": "ظهور لمنشأتك التجارية في تطبيق على الموقع الالكتروني الخاص بزفاف"},
                        {"content": "وصف خاص للمشروع"},
                        {"content": "زر للتواصل من خلال الواتساب"},
                        {"content": "عدد لانهائي من الخصائص ومزايا المنشأة التجارية"},
                        {"content": "ألبوم صور يصل حتى 10 صور"},
                        {"content": "رمز QR خاص بالمنشأة التجارية "},
                        {"content": "خدمة عملاء لتسهيل التعامل مع الزبائن محتملين"},
                    ]
                },
                famousPlan: {
                    "name": "الشاملة",
                    "price": "300",
                    "oldPrice": "360",
                    "data": [
                        {"content": "ظهور لمنشأتك التجارية في تطبيق على الهواتف الذكية"},
                        {"content": "ظهور لمنشأتك التجارية في تطبيق على الموقع الالكتروني الخاص بزفاف"},
                        {"content": "وصف خاص للمشروع"},
                        {"content": "زر للتواصل من خلال الواتساب"},
                        {"content": "عدد لانهائي من الخصائص ومزايا المنشأة التجارية"},
                        {"content": "ألبوم صور يصل حتى 25 صور"},
                        {"content": "رمز QR خاص بالمنشأة التجارية توصل العملاء لصفحتكم "},
                        {"content": "خدمة عملاء لتسهيل التعامل مع الزبائن محتملين"},
                        {"content": "كتابة مقالة عن منشأتك التجارية أو ذكرها في أحد مقالاتنا الأسبوعية"},
                        {"content": "ظهور منشأتك التجارية باعلاناتنا الخاصة على السوشيال ميديا"},
                        {"content": "إعلانات تخص منشأتك التجارية على اشعارات لتطبيقنا"},
                        {"content": "حساب خاص في نظام إدارة محتوى زفاف - ZDash للقدرة على تحديث معلومات صفحة منشأتك التجارية أو تنزيل منشورات خاصة بكم."},
                        {"content": "ظهور إعلانات خاصة لمنشأتك التجارية في بانرات التسويق داخل تطبيقات زفاف"},
                    ]
                },
                mainPlan2: {
                    "name": "التسويقية",
                    "price": "420",
                    "oldPrice": "480",
                    "data": [
                        {"content": "رمز QR خاص بالمنشأة التجارية توصل العملاء لصفحتكم"},
                        {"content": "خدمة عملاء لتسهيل التعامل مع الزبائن محتملين"},
                        {"content": "كتابة مقالة عن منشأتك التجارية أو ذكرها في أحد مقالاتنا الأسبوعية"},
                        {"content": "ظهور منشأتك التجارية باعلاناتنا الخاصة على السوشيال ميديا"},
                        {"content": "إعلانات تخص منشأتك التجارية على اشعارات لتطبيقنا"},
                        {"content": "حساب خاص في نظام إدارة محتوى زفاف - ZDash للقدرة على تحديث معلومات صفحة منشأتك التجارية أو تنزيل منشورات خاصة بكم."},
                        {"content": "ظهور إعلانات خاصة لمنشأتك التجارية في بانرات التسويق داخل تطبيقات زفاف"},
                    ]
                },
            }
            ,
            threeMonthsPlan: {
                mainPlan1: {
                    "name": "الأساسية",
                    "price": 70,
                    "oldPrice": "",
                    "data": [
                        {"content": "ظهور لمنشأتك التجارية في تطبيق على الهواتف الذكية"},
                        {"content": "ظهور لمنشأتك التجارية في تطبيق على الموقع الالكتروني الخاص بزفاف"},
                        {"content": "وصف خاص للمشروع"},
                        {"content": "زر للتواصل من خلال الواتساب"},
                        {"content": "عدد لانهائي من الخصائص ومزايا المنشأة التجارية"},
                        {"content": "ألبوم صور يصل حتى 10 صور"},
                        {"content": "رمز QR خاص بالمنشأة التجارية "},
                        {"content": "خدمة عملاء لتسهيل التعامل مع الزبائن محتملين"},
                    ]
                },
                famousPlan: {
                    "name": "الشاملة",
                    "price": 90,
                    "oldPrice": "",
                    "data": [
                        {"content": "ظهور لمنشأتك التجارية في تطبيق على الهواتف الذكية"},
                        {"content": "ظهور لمنشأتك التجارية في تطبيق على الموقع الالكتروني الخاص بزفاف"},
                        {"content": "وصف خاص للمشروع"},
                        {"content": "زر للتواصل من خلال الواتساب"},
                        {"content": "عدد لانهائي من الخصائص ومزايا المنشأة التجارية"},
                        {"content": "ألبوم صور يصل حتى 25 صور"},
                        {"content": "رمز QR خاص بالمنشأة التجارية توصل العملاء لصفحتكم "},
                        {"content": "خدمة عملاء لتسهيل التعامل مع الزبائن محتملين"},
                        {"content": "كتابة مقالة عن منشأتك التجارية أو ذكرها في أحد مقالاتنا الأسبوعية"},
                        {"content": "ظهور منشأتك التجارية باعلاناتنا الخاصة على السوشيال ميديا"},
                        {"content": "إعلانات تخص منشأتك التجارية على اشعارات لتطبيقنا"},
                        {"content": "حساب خاص في نظام إدارة محتوى زفاف - ZDash للقدرة على تحديث معلومات صفحة منشأتك التجارية أو تنزيل منشورات خاصة بكم."},
                        {"content": "ظهور إعلانات خاصة لمنشأتك التجارية في بانرات التسويق داخل تطبيقات زفاف"},
                    ]
                },
                mainPlan2: {
                    "name": "التسويقية",
                    "price": 120,
                    "oldPrice": "",
                    "data": [
                        {"content": "رمز QR خاص بالمنشأة التجارية توصل العملاء لصفحتكم"},
                        {"content": "خدمة عملاء لتسهيل التعامل مع الزبائن محتملين"},
                        {"content": "كتابة مقالة عن منشأتك التجارية أو ذكرها في أحد مقالاتنا الأسبوعية"},
                        {"content": "ظهور منشأتك التجارية باعلاناتنا الخاصة على السوشيال ميديا"},
                        {"content": "إعلانات تخص منشأتك التجارية على اشعارات لتطبيقنا"},
                        {"content": "حساب خاص في نظام إدارة محتوى زفاف - ZDash للقدرة على تحديث معلومات صفحة منشأتك التجارية أو تنزيل منشورات خاصة بكم."},
                        {"content": "ظهور إعلانات خاصة لمنشأتك التجارية في بانرات التسويق داخل تطبيقات زفاف"},


                    ]
                },
            },
            sixMonthsPlan: {
                mainPlan1: {
                    "name": "الأساسية",
                    "price": 140,
                    "oldPrice": "",
                    "data": [
                        {"content": "ظهور لمنشأتك التجارية في تطبيق على الهواتف الذكية"},
                        {"content": "ظهور لمنشأتك التجارية في تطبيق على الموقع الالكتروني الخاص بزفاف"},
                        {"content": "وصف خاص للمشروع"},
                        {"content": "زر للتواصل من خلال الواتساب"},
                        {"content": "عدد لانهائي من الخصائص ومزايا المنشأة التجارية"},
                        {"content": "ألبوم صور يصل حتى 10 صور"},
                        {"content": "رمز QR خاص بالمنشأة التجارية "},
                        {"content": "خدمة عملاء لتسهيل التعامل مع الزبائن محتملين"},
                    ]
                },
                famousPlan: {
                    "name": "الشاملة",
                    "price": 180,
                    "oldPrice": "",
                    "data": [
                        {"content": "ظهور لمنشأتك التجارية في تطبيق على الهواتف الذكية"},
                        {"content": "ظهور لمنشأتك التجارية في تطبيق على الموقع الالكتروني الخاص بزفاف"},
                        {"content": "وصف خاص للمشروع"},
                        {"content": "زر للتواصل من خلال الواتساب"},
                        {"content": "عدد لانهائي من الخصائص ومزايا المنشأة التجارية"},
                        {"content": "ألبوم صور يصل حتى 25 صور"},
                        {"content": "رمز QR خاص بالمنشأة التجارية توصل العملاء لصفحتكم "},
                        {"content": "خدمة عملاء لتسهيل التعامل مع الزبائن محتملين"},
                        {"content": "كتابة مقالة عن منشأتك التجارية أو ذكرها في أحد مقالاتنا الأسبوعية"},
                        {"content": "ظهور منشأتك التجارية باعلاناتنا الخاصة على السوشيال ميديا"},
                        {"content": "إعلانات تخص منشأتك التجارية على اشعارات لتطبيقنا"},
                        {"content": "حساب خاص في نظام إدارة محتوى زفاف - ZDash للقدرة على تحديث معلومات صفحة منشأتك التجارية أو تنزيل منشورات خاصة بكم."},
                        {"content": "ظهور إعلانات خاصة لمنشأتك التجارية في بانرات التسويق داخل تطبيقات زفاف"},
                    ]

                },
                mainPlan2: {
                    "name": "التسويقية",
                    "price": 240,
                    "oldPrice": "",
                    "data": [
                        {"content": "رمز QR خاص بالمنشأة التجارية توصل العملاء لصفحتكم"},
                        {"content": "خدمة عملاء لتسهيل التعامل مع الزبائن محتملين"},
                        {"content": "كتابة مقالة عن منشأتك التجارية أو ذكرها في أحد مقالاتنا الأسبوعية"},
                        {"content": "ظهور منشأتك التجارية باعلاناتنا الخاصة على السوشيال ميديا"},
                        {"content": "إعلانات تخص منشأتك التجارية على اشعارات لتطبيقنا"},
                        {"content": "حساب خاص في نظام إدارة محتوى زفاف - ZDash للقدرة على تحديث معلومات صفحة منشأتك التجارية أو تنزيل منشورات خاصة بكم."},
                        {"content": "ظهور إعلانات خاصة لمنشأتك التجارية في بانرات التسويق داخل تطبيقات زفاف"},
                    ]
                },
            }
        }

        const [plan, setPlan] = useState(plans.yearlyPlan);
        const [activePlan, setactivePlan] = useState("year");

        console.log(plan)
        const func = (event, p) => {
            if (p == "3MonthsPlan") {
                setPlan(plans.threeMonthsPlan);
                setactivePlan("3month")
            } else if (p == "6MonthsPlan") {
                setPlan(plans.sixMonthsPlan)
                setactivePlan("6month")
            } else if (p == "yearlyPlan") {
                setPlan(plans.yearlyPlan)
                setactivePlan("year")
            }
        };
        if (plan == undefined || null) {
            return;
        }

        return (
            <div>
                <div className="price">
                    <div className="top-page">
                        <div className="first-par">
                            <p>المنصة الأولى محليا التي تختص بسوق الأفراح</p>
                            <p>وسع رقعة زبائنك من كافة أنحاء البلاد</p>
                        </div>
                        <div className="second-par">
                            <p>الخطوة الأولى</p>
                            <p>اختر الخطة الأنسب لمنشأتك التجارية</p>
                        </div>
                        <div className="plan-type">
                            <button
                                className={`plan-list ${
                                    activePlan == "year" ? "active-plan" : ""
                                }`} onClick={(e) => {
                                func(e, "yearlyPlan")
                            }}
                            >سنة
                            </button>
                            <button
                                className={`plan-list ${
                                    activePlan == "6month" ? "active-plan" : ""
                                }`}
                                onClick={(e) => {
                                    func(e, "6MonthsPlan")
                                }}>6 أشهر
                            </button>
                            <button
                                className={`plan-list ${
                                    activePlan == "3month" ? "active-plan" : ""
                                }`}
                                onClick={(e) => {
                                    func(e, "3MonthsPlan")
                                }}>3 أشهر
                            </button>
                        </div>
                        <div className="plan">
                            <div className="main-plan">
                                <div className="plan-title">
                                    <p>{plan.mainPlan1.name}</p>
                                </div>
                                <div className={
                                    plan.mainPlan1.oldPrice == "" ? "big-size-price" : "plan-price new-plan"
                                }>
                                    <p>{plan.mainPlan1.price}$</p>
                                </div>
                                <div className="plan-content black">
                                    <div className="plan-cont-par">
                                        <p>ستحصل على صفحة خاصة لمنشأتك التجارية وتحتوي على:</p>
                                    </div>
                                    {plan.mainPlan1.data.map((cont) => (
                                        <div className="content">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="19" height="13"
                                                 viewBox="0 0 19 13"
                                                 fill="none">
                                                <path
                                                    d="M6.03809 10.2565L1.53383 6.21402L0 7.58091L6.03809 13L19 1.36689L17.477 0L6.03809 10.2565Z"
                                                    fill="#0080FF"/>
                                            </svg>
                                            <p>{cont.content}</p>
                                        </div>
                                    ))

                                    }
                                    <div className="zifaf-logo">
                                        <img src="/images/zifaf_FinalLogo.png"/>
                                    </div>
                                    <button>اختر الخطة الأساسية</button>
                                </div>
                            </div>

                            <div className="famous-plan">
                                <div className="most-famous">
                                    <p>الأكثر شهرة</p>
                                </div>
                                <div className="famous-plan-title">
                                    <p>{plan.famousPlan.name}</p>
                                </div>
                                {plan.famousPlan.oldPrice == "" ? null : <div className="old-price">
                                    <span className="line">{plan.famousPlan.oldPrice}$</span>
                                </div>}
                                <div className={plan.famousPlan.oldPrice == "" ? "old-price" : "famous-plan-price"}>

                                    <p>{plan.famousPlan.price}$</p>
                                </div>
                                <div className="famous-plan-content">
                                    <div className="famous-plan-cont-par">
                                        <p>ستحصل على صفحة خاصة لمنشأتك التجارية وتحتوي على:</p>
                                    </div>
                                    {plan.famousPlan.data.map((cont) => (
                                        <div className="content white">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="19" height="13"
                                                 viewBox="0 0 19 13"
                                                 fill="none">
                                                <path
                                                    d="M6.03809 10.2565L1.53383 6.21402L0 7.58091L6.03809 13L19 1.36689L17.477 0L6.03809 10.2565Z"
                                                    fill="#0080FF"/>
                                            </svg>
                                            <p>{cont.content}</p>
                                        </div>
                                    ))

                                    }
                                    <div className="zifaf-logo">
                                        <img src="/images/white_logo.svg"/>
                                    </div>
                                    <button>اختر الخطة الشاملة</button>

                                </div>


                            </div>

                            <div className="main-plan">
                                <div className="plan-title black">
                                    <p>{plan.mainPlan2.name}</p>
                                </div>
                                {plan.mainPlan2.oldPrice == "" ? null : <div className="plan-price">
                                    <span className="line">{plan.mainPlan2.oldPrice}$</span>
                                </div>}
                                <div
                                    className={
                                        plan.mainPlan2.oldPrice == "" ? "big-size-price" : "plan-price new-plan"
                                    }
                                >
                                    <p>{plan.mainPlan2.price}$</p>
                                </div>
                                <div className="plan-content black">
                                    <div className="plan-cont-par">
                                        <p>ستحصل على صفحة خاصة لمنشأتك التجارية وتحتوي على:</p>
                                    </div>
                                    {plan.mainPlan2.data.map((cont) => (
                                        <div className="content">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="19" height="13"
                                                 viewBox="0 0 19 13"
                                                 fill="none">
                                                <path
                                                    d="M6.03809 10.2565L1.53383 6.21402L0 7.58091L6.03809 13L19 1.36689L17.477 0L6.03809 10.2565Z"
                                                    fill="#0080FF"/>
                                            </svg>
                                            <p>{cont.content}</p>
                                        </div>
                                    ))

                                    }
                                    <div className="zifaf-logo">
                                        <img src="/images/zifaf_FinalLogo.png"/>
                                    </div>
                                    <button>اختر الخطة الأساسية</button>

                                </div>


                            </div>
                        </div>

                    </div>
                </div>

            </div>
        );
    }
;
export default Price;
