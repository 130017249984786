import React, { useState } from "react";
import Logo from "../Logo";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars } from "@fortawesome/free-solid-svg-icons";
import { library } from "@fortawesome/fontawesome-svg-core";
import LandingPageNavbarElement from "./LandingPageNavbarElement";
library.add(faBars);

const NavbarLandingPage = (props) => {
  let isResponsive = window.innerWidth <= 768 ? false : true;
  const [isOpened, setIsOpened] = useState(isResponsive);

  function toggle() {
    console.log(window.innerWidth);
    setIsOpened((isOpened) => !isOpened);
  }

  return (
    <div className="navbar-landing">
      <div className="logo-wrapper">
        <Logo color="black" />
      </div>
      <div className="responsive-navbars" onClick={() => toggle()}>
        <FontAwesomeIcon icon="fa-solid fa-bars" />
      </div>
      {isOpened && (
          <div className="nav-landing-elements">
            <LandingPageNavbarElement navText=" الرئيسية " scrollTo="/" />
            <LandingPageNavbarElement navText=" تطبيقنا " scrollTo="/app" />
            <LandingPageNavbarElement navText=" صاحب مشروع؟ " scrollTo="/join" />
            <LandingPageNavbarElement navText=" التسعيرة " scrollTo="/price" />

            {/* <LandingPageNavbarElement navText=" إلهام وأفكار " scrollTo="/" /> */}
          </div>
      )}
      <div className="icon-user">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="30"
          height="30"
          viewBox="0 0 30 30"
          fill="none"
        >
          <path
            d="M15 3.5625C17.175 3.5625 18.9375 5.325 18.9375 7.5C18.9375 9.675 17.175 11.4375 15 11.4375C12.825 11.4375 11.0625 9.675 11.0625 7.5C11.0625 5.325 12.825 3.5625 15 3.5625ZM15 20.4375C20.5688 20.4375 26.4375 23.175 26.4375 24.375V26.4375H3.5625V24.375C3.5625 23.175 9.43125 20.4375 15 20.4375ZM15 0C10.8562 0 7.5 3.35625 7.5 7.5C7.5 11.6438 10.8562 15 15 15C19.1438 15 22.5 11.6438 22.5 7.5C22.5 3.35625 19.1438 0 15 0ZM15 16.875C9.99375 16.875 0 19.3875 0 24.375V30H30V24.375C30 19.3875 20.0063 16.875 15 16.875Z"
            fill="#FFC61B"
          />
        </svg>
      </div>
    </div>
  );
};

export default NavbarLandingPage;
