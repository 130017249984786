import React from "react";
import ContactInfo from './ContactInfo';
import ColumnTitle from './ColumnTitle'
import FooterWave from "./FooterWave";
import FooterForm from "./FooterForm";
import DownloadFooter from "./DownloadFooter";
import Copyrights from "./Copyrights";

class Footer extends React.Component {
    render() {
        return (
            <div className="footer">
                <FooterWave />
                <div className="container">
                    <div className="footer-row">
                        <div className="footer-col">
                            <ColumnTitle title="تواصل معنا" />
                            <ContactInfo contactTitle="العنوان" contactContent="وادي الجوز - ش.خالد بن الوليد 38
القدس" icon="location-dot" />
                            <ContactInfo contactTitle="إتصل بنا" contactContent="058-649-0493" icon="phone" />
                            <ContactInfo contactTitle="البريد الإلكتروني" contactContent="info@zifaff.com" icon="envelope" />
                        </div>
                        <div className="footer-col">
                            <ColumnTitle title="إترك رقمك" />
                            <FooterForm />
                        </div>
                        <div className="footer-col">
                            <ColumnTitle title="حمل التطبيق الآن" />
                            <DownloadFooter />
                        </div>
                    </div>
                    <Copyrights />
                </div>
            </div>
        );
    }
}

export default Footer